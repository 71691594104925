import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { ModalContainer } from '../modals';
import { forwardRef, useImperativeHandle, useState } from 'react';

export interface AlertPopupProps {
  message?: string;
  title?: string;
  status?: 'SUCCESS' | 'ERROR';
  onClick?: () => void;
}

export interface AlertPopupRef {
  show: (props: AlertPopupProps) => void;
}

const AlertPopup = forwardRef((_, ref?: any) => {
  const [msg, setMsg] = useState<AlertPopupProps | null>(null);

  const onClose = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    msg && msg.onClick && msg.onClick();
    setMsg(null);
  };

  const show = (_msg: AlertPopupProps) => {
    setMsg(_msg);
  };

  useImperativeHandle(ref, () => ({ show }));

  return (
    <ModalContainer visible={true} hidden={!msg} className="z-[100]">
      <div className="flex-1 items-center justify-center p-2">
        <LazyLoadComponent>
          <div className="bg-white p-2 rounded-lg w-full max-w-md relative border-5 border:bg-slate-100 shake">
            <div className="mb-2 items-center justify-center w-full min-h-[6rem] max-h-52 overflow-auto bg-gray-50 rounded">
              <label className="text-center text-sm md:text-base text-[#333] font-battambang">{msg?.message}</label>
            </div>
            <button
              autoFocus
              onClick={onClose}
              onSubmit={onClose}
              className={`text-white rounded-md py-1.5 text-sm md:text-base font-battambang ${
                msg?.status === 'ERROR' ? 'bg-error' : 'bg-success'
              }`}>
              {msg?.title || 'យល់ព្រម'}
            </button>
          </div>
        </LazyLoadComponent>
      </div>
    </ModalContainer>
  );
});

export default AlertPopup;
