import { ReactNode } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

interface ModalContainerProps {
  children?: ReactNode;
  onClose?: () => void;
  visible?: boolean;
  hidden?: boolean;
  className?: string;
}

export default function ModalContainer(props: ModalContainerProps) {
  const onClose = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClose && props.onClose();
  };

  if (props.visible) {
    return (
      <div
        onClick={onClose}
        className={`flex-1 min-w-full min-h-full fixed z-50 bg-[#00000070] top-0 left-0 ${
          props.hidden ? 'hidden' : 'flex'
        } ${props.className}`}>
        <LazyLoadComponent>{props.children}</LazyLoadComponent>
      </div>
    );
  }
  return null;
}
