import Paths from "src/paths";
import { SWROptions, useMutateSWR, useQuerySWR } from "../swr-instanct";
import { Types } from "src/generated/types";

const User = Paths.endpoints.user;

export interface UserDetailResponse {
  balance: number,
  auth: Types.Auth,
  setting: Types.Setting
  enableFastGamePlay: boolean
}

export function useUserDetail(options?: SWROptions<UserDetailResponse>) {
  return useQuerySWR(User.detail, {}, options)
}

interface UserChangePasswordParams {
  oldPassword: string, newPassword: string, confirmPassword: string
}

export function useUserChangePassword(params: UserChangePasswordParams, options?: SWROptions) {
  return useMutateSWR(User.changePassword, {
    old_password: params.oldPassword,
    new_password: params.newPassword,
    confirm: params.confirmPassword,
  }, options);
}

