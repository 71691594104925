const ImageAssets = {
  Name: require('./resources/name.webp'),
  Logo: require('./resources/logo.webp'),
  Thumbnail: require('./resources/thumbnail.webp'),
  Background: require('./resources/background.webp'),
  CircleLogo: require('./resources/circle-logo.webp'),
  Promotion: require('./resources/promotion.webp'),
  Maintenance: require('./resources/maintain.webp'),
  Jackpot: require('./resources/jackpot.webp'),
  Sphere: require('./resources/sphere.png'),
  RandomGif: require('./resources/random.gif'),
  WaitingGit: require('./resources/waiting.gif'),
  Stick: require('./resources/stick.png'),
  Ball: require('./resources/ball.png'),
  Counting: require('./resources/counting.jpeg'),
  Dragon: require('./resources/dragon.png'),
  GuideBet: require('./resources/guide-bet.png'),
  GuideResult: require('./resources/guide-result.png'),
  WinnerGif: require('./resources/winner.gif'),
  DragonGif: require('./resources/dragon.gif'),
  JackpotWinner: require('./resources/jackpot_winner.webp'),
  Page404: require('./resources/page-404.webp')
}

export default ImageAssets;