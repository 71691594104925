import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { MaintenanceView } from 'src/screens/maintenances/views';

// ----------------------------------------------------------------------

export default function MaintenancePage() {
  return (
    <>
      <Helmet>
        <title>Maintenance</title>
      </Helmet>
      <MaintenanceView />
    </>
  );
}
