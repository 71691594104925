import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageAssets from 'src/assets/images';
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/contexts/auth-provider';
import { useRouter } from 'src/hooks';
import Formats from 'src/utils/format';

interface LinkIconProps {
  icon: string;
  href?: string;
  className?: string;
  onClick?: VoidFunction;
}

function LinkIcon(props: LinkIconProps) {
  return (
    <a
      href={props.href}
      onClick={props.onClick}
      className={`flex px-1 h-10 md:h-11 cursor-pointer items-center justify-center ${props.className}`}>
      <Iconify icon={props.icon} color="white" className="h-6 w-6 md:h-7 md:w-7" />
    </a>
  );
}

interface MainHeaderProps {
  onChangeMenuVisible?: VoidFunction;
}

export default function MainHeader(props: MainHeaderProps) {
  const router = useRouter();
  const { auth, balance } = useAuthContext();
  return (
    <div className="bg-primary flex-row min-h-10 md:min-h-11 px-1 items-center">
      <LinkIcon icon={'line-md:menu'} className={`lg:w-10 lg:mr-0.5`} onClick={props.onChangeMenuVisible} />
      <LinkIcon onClick={() => router.push('/')} icon="line-md:home-twotone" className="md:hidden" />
      <a href="/" className="hidden md:block">
        <LazyLoadImage src={ImageAssets.Logo} className="ml-1 h-10" />
      </a>
      <label className="text-white text-xs md:text-sm lg:text-base px-2 font-bold">
        {auth?.username.toUpperCase()}
      </label>
      <div className="flex-1 items-center justify-center">
        <LazyLoadImage src={ImageAssets.Name} className="h-8 lg:h-9 hidden md:block" />
      </div>
      <label className="text-white text-xs md:text-sm lg:text-base px-2 font-bold">
        ទឹកប្រាក់: {Formats.currency(balance || 0)}៛
      </label>
      <LinkIcon onClick={() => router.reload()} icon="line-md:backup-restore" className="md:hidden" />
      {/* <a href="/lotto" className="mx-1 hidden md:block">
        <button className="w-24 h-9 border-[1px] md:border-1 border-white border-solid rounded md:rounded-lg bg-primary text-white text-sm hover:bg-white hover:text-primary">
          ប្ដូរទម្រង់ចាស់
        </button>
      </a> */}
      <a onClick={() => router.reload()} className="mx-1 hidden md:block">
        <button className="w-24 h-9 border-[1px] md:border-1 border-white border-solid rounded md:rounded-lg bg-primary text-white text-sm hover:bg-white hover:text-primary">
          បើកម្ដងទៀត
        </button>
      </a>
    </div>
  );
}
