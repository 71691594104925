import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageAssets from 'src/assets/images';
import { useRouter } from 'src/hooks';

export default function MaintenanceView() {
  const router = useRouter();
  return (
    <div className="flex-1 items-center justify-center bg-primary px-[5%] pb-[5%]">
      <LazyLoadImage src={ImageAssets.Maintenance} className="w-[90%] md:w-[55%] mb-10" />
      <label className="text-xl md:text-3xl text-center font-battambang text-white">
        ប្រព័ន្ធស្នូលកំពុងតែស្ថិតក្រោមការថែទាំនឹងធ្វើបច្ចុប្បន្នភាព។
      </label>
      <button
        onClick={() => router.reload()}
        className="mt-10 text-primary p-3 rounded-lg px-10 bg-white font-battambang">
        ត្រឡប់ទៅទំព័រដើម
      </button>
    </div>
  );
}
