import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router';
import { Types } from 'src/generated/types';

const Error404Page = lazy(() => import('src/pages/errors/error-404-page'));
const MaintenancePage = lazy(() => import('src/pages/maintenances/maintenance-page'));

export const errorRouters: Types.RouteObject[] = [
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [
      { path: '404', element: <Error404Page /> },
      { path: 'maintenance', element: <MaintenancePage /> },
    ],
  },
];
