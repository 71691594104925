import { ReactNode } from 'react';
import { HomeHeader } from './components';
import ImageAssets from 'src/assets/images';

export default function HomeLayout({ children }: { children?: ReactNode }) {
  return (
    <div
      className="bg-cover max-h-screen min-h-screen overflow-hidden"
      style={{ backgroundImage: `url(${ImageAssets.Background})` }}>
      <HomeHeader />
      {children}
    </div>
  );
}
