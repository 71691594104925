import { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageAssets from 'src/assets/images';
import { useAuthContext } from 'src/contexts/auth-provider';
import Paths from 'src/paths';

export default function HomeHeader() {
  const { auth } = useAuthContext();
  const isAuthLogin = useMemo(() => !!auth, [auth]);

  return (
    <div className="bg-[#4e4e4e1c]" style={{ boxShadow: '1px 3px 10px 0 #00000063' }}>
      <div className="container h-12 md:h-16 flex-row px-2 mx-auto items-center">
        <a onClick={() => window.location.reload()}>
          <LazyLoadImage src={ImageAssets.Logo} className="w-20 md:w-28 lg:w-32" />
        </a>
        <div className="flex-1 items-center justify-center">
          <LazyLoadImage src={ImageAssets.Name} className="h-8 md:h-11 hidden sm:block" />
        </div>
        <button
          onClick={() => window.open(isAuthLogin ? Paths.redirectAfterLogin : Paths.redirectLogin, '_self')}
          className="mx-1 w-20 md:w-28 lg:w-32 h-7 md:h-10 lg:h-12 border-[1px] md:border-1 border-white border-solid rounded md:rounded-xl bg-primary text-white text-xs md:text-lg">
          {isAuthLogin ? 'ចាប់ផ្ដើមលេង' : 'ចូលលេង'}
        </button>
      </div>
    </div>
  );
}
