const SoundAssets = {
  BALLS: require('./resources/balls.mp3'),
  CAGE: require('./resources/cage.mp3'),
  CLICK: require('./resources/click.mp3'),
  COMPLETE: require('./resources/complete.mp3'),
  FAIL: require('./resources/fail.mp3'),
  NUMBER: require('./resources/number.mp3'),
  RANDOM: require('./resources/random.mp3'),
  REVEAL: require('./resources/reveal.mp3'),
  START: require('./resources/goodluck.mp3'),
  SUCK: require('./resources/suck.mp3'),
  WIN: require('./resources/win.mp3'),
  COUNT: require('./resources/count.mp3'),
  CLAP: require('./resources/claps.wav')
};

export default SoundAssets;