import { ReactNode, createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';
import { useLogoutAuth } from 'src/api/auth';
import { SWRErrorResponse } from 'src/api/swr-instanct';
import { UserDetailResponse, useUserDetail } from 'src/api/user';
import { LogoLoading } from 'src/components/loadings';
import { Types } from 'src/generated/types';
import { useRouter } from 'src/hooks';
import { useAnalyticScreen, useAnalyticUser } from 'src/hooks/use-analytic';
import MaintenancePage from 'src/pages/maintenances/maintenance-page';
import Paths from 'src/paths';
import { removeAllCookie, resetOldToken } from 'src/utils';

type ActionType = 'AUTH_DETAIL' | 'AUTH_BALANCE' | 'AUTH_LOGOUT';

interface AuthState {
  balance?: number;
  auth?: Types.Auth | null;
  setting?: Types.Setting | null;
  enableFastGamePlay?: boolean;
}

interface AuthAction {
  getAuth: VoidFunction;
  logoutAuth: VoidFunction;
  setBalance: (balance: number) => void;
}

interface AuthProvider extends AuthState, AuthAction {}

const InitAuth: AuthProvider = {
  auth: null,
  balance: 0,
  setting: null,
  enableFastGamePlay: true,
  getAuth: () => {},
  logoutAuth: () => {},
  setBalance: (balance: number) => {},
};

const AuthContext = createContext<AuthProvider>(InitAuth);
const AuthProvider = AuthContext.Provider;

export default ({ children }: { children?: ReactNode }) => {
  const router = useRouter();
  const location = useLocation();
  const [cookies, setCookies, removeCookies] = useCookies();
  const isPublicRoute = useMemo(() => Paths.publics.includes(location.pathname), [location.pathname]);

  const [loading, setLoading] = useState<boolean>(true);
  const [maintenance, setMaintenance] = useState<boolean>(false);

  const [state, dispatch] = useReducer(
    (preState: AuthProvider, action: Types.ContextAction<ActionType>): AuthProvider => {
      switch (action.type) {
        case 'AUTH_DETAIL':
          return { ...preState, ...action.payload };
        case 'AUTH_BALANCE':
          return { ...preState, balance: action.payload.balance || 0 };
        case 'AUTH_LOGOUT':
          return { ...preState, ...InitAuth };
        default:
          return preState;
      }
    },
    InitAuth,
  );

  const ctx = useMemo(
    () => ({
      ...state,
      setAuth: (payload: UserDetailResponse) => {
        dispatch({ payload, type: 'AUTH_DETAIL' });
      },
      setBalance: (balance: number) => {
        dispatch({ payload: { balance }, type: 'AUTH_BALANCE' });
      },
      logoutAuth: () => {
        removeAllCookie();
        removeCookies('XSRF-TOKEN');
        dispatch({ type: 'AUTH_LOGOUT' });
      },
    }),
    [state],
  );

  const onSuccess = (r: UserDetailResponse) => {
    ctx.setAuth(r);
    useAnalyticUser(r.auth.id);
    if (
      (!cookies?.poll && r.enableFastGamePlay) ||
      (r.setting && !r.setting.enabled_poll_1 && cookies.poll === 'POLL_1')
    ) {
      setCookies('poll', 'POLL_2', { path: '/' });
    }
    if (location.pathname === Paths.redirectLogin) {
      window.open(Paths.redirectAfterLogin, '_self');
    }
    loading && setLoading(false);
  };

  const onError = (e: SWRErrorResponse) => {
    if (e && e.status === 503 && !maintenance) {
      setMaintenance(true);
    }
    loading && setLoading(false);
  };

  useEffect(() => {
    if (!loading && !ctx.auth && !isPublicRoute) {
      window.open(Paths.redirectLogin, '_self');
    } else if (!loading && ctx.auth && location.pathname === Paths.redirectLogin) {
      router.replace(Paths.redirectAfterLogin);
    }
  }, [loading, ctx.auth, isPublicRoute]);

  useEffect(() => {
    if (!loading) {
      useAnalyticScreen(location.pathname, location.search, ctx.auth?.id);
    }
  }, [loading, location]);

  useUserDetail({
    loadFirst: true,
    disabledAlertError: true,
    onSuccess,
    onError,
  });

  const { refetch: getAuth } = useUserDetail({
    onSuccess,
    onError,
  });

  useEffect(() => {
    resetOldToken();
  }, []);

  const renderScreen = () => {
    if (loading) {
      return <LogoLoading />;
    } else if (maintenance) {
      return <MaintenancePage />;
    }
    return children;
  };

  return <AuthProvider value={{ ...ctx, getAuth }}>{renderScreen()}</AuthProvider>;
};

export const useAuthContext = () => useContext(AuthContext);
