import { Types } from 'src/generated/types';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { errorRouters } from './error';
import { authRouters } from './auth';
import { mainRouters } from './main';
import Paths from 'src/paths';

export default function Router() {
  const routers: Types.RouteObject[] = [
    ...authRouters,
    ...mainRouters,
    ...errorRouters,
    // No match 404
    { path: '*', element: <Navigate to={Paths.redirectAfterLogin} replace /> },
  ];
  return useRoutes(routers as RouteObject[]);
}
