import { Types } from "src/generated/types";

const header = '';

const publics = ['/', `${header}/login`, `${header}/maintenance`];

const routes = {
  home: {
    root: `${header}/`
  },
  auth: {
    login: `${header}/login`,
    logout: `${header}/logout`
  },
  game: {
    lotto59: `${header}/game/lotto/59`
  },
  user: {
    password: `${header}/user/password`
  },
  report: {
    lotto59: `${header}/report/lotto/59`,
  },
  ticket: {
    lotto59: `${header}/ticket/lotto59`
  },
  transaction: {
    deposit: `${header}/transaction/deposit`,
    withdraw: `${header}/transaction/withdraw`
  }
}

const menus: Types.MenuSliderItem[] = [
  {
    title: '1. ឡូតូសេដ្ឋីនាគ',
    icon: 'icon-park-solid:game-ps',
    children: [
      {
        title: '1.1 លេងឡូតូសេដ្ឋីនាគ',
        icon: 'solar:asteroid-bold',
        path: routes.game.lotto59
      },
      // {
      //   title: '1.2 លេងឡូតូសេដ្ឋីនាគថ្មី',
      //   icon: 'solar:asteroid-bold',
      //   path: routes.game.lotto59
      // },
      {
        title: '1.2 ផ្ទៀងផ្ទាត់សំបុត្រ',
        icon: 'mdi:paper-search',
        path: routes.ticket.lotto59
      }
    ],
  },
  {
    title: '2. ការដកប្រាក់',
    icon: 'vaadin:money-withdraw',
    children: [
      {
        title: '2.1 ប្រវត្តិដកប្រាក់',
        icon: 'vaadin:cash',
        path: routes.transaction.withdraw
      }
    ],
  },
  {
    title: '3. ការដាក់ប្រាក់',
    icon: 'vaadin:money-deposit',
    children: [
      {
        title: '3.1 ប្រវត្តិដាក់ប្រាក់',
        icon: 'vaadin:cash',
        path: routes.transaction.deposit
      }
    ],
  },
  {
    title: '4. របាយការណ៍',
    icon: 'fluent-mdl2:mobile-report',
    children: [
      {
        title: '4.1 របាយការណ៍លេង',
        icon: 'mdi:report-finance',
        path: routes.report.lotto59
      }
    ],
  },
  {
    title: '5. ប្ដូរពាក្យសម្ងាត់',
    icon: 'mingcute:lock-fill',
    path: routes.user.password
  },
  {
    title: '6. ចាកចេញ',
    icon: 'raphael:power',
    path: routes.auth.logout
  },
  // {
  //   title: '7. ប្ដូរទម្រង់ចាស់',
  //   icon: 'ic:twotone-phonelink',
  //   path: '/'
  // }
]

const endpoints = {
  poll: '/api/poll',
  print: '/api/print',
  auth: {
    login: '/login',
    logout: '/logout',
    crsf: '/sanctum/csrf-cookie',
  },
  user: {
    detail: '/api/user',
    changePassword: '/api/user/change_password',
  },
  jackpot: {
    search: '/api/jackpot/search',
    winner: '/api/v1/jackpot/winner',
  },
  result: {
    home: '/api/result/history',
    recent: '/api/v1/result/recent',
    history: '/api/v1/result/history',
    playing: '/api/v1/result/playing',
    releasing: '/api/v1/result/releasing',
  },
  report: {
    bet: '/api/bet/report'
  },
  transaction: {
    list: '/api/transaction/list'
  },
  ticket: {
    list: '/api/v1/ticket/list',
    badge: '/api/v1/ticket/badge'
  },
  bet: {
    range: '/api/bet',
    jackpot: '/api/bet/jackpot'
  }
}

const Paths = {
  menus,
  routes,
  publics,
  endpoints,
  redirectLogin: routes.auth.login,
  redirectAfterLogin: routes.game.lotto59
}

export default Paths;