import { ThreeDots } from 'react-loader-spinner';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import ImageAssets from 'src/assets/images';

export default function LogoLoading() {
  return (
    <LazyLoadComponent>
      <div className="flex-1 items-center justify-center px-[5%] pb-[5%]">
        <LazyLoadImage src={ImageAssets.Logo} className="animate-bounce mb-5 w-52 md:w-80" />
        <ThreeDots color="#F00" height={20} />
      </div>
    </LazyLoadComponent>
  );
}
