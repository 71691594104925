import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import SoundAssets from 'src/assets/sounds';
import { useEventListener } from 'src/hooks';
import { __DEV__ } from 'src/utils';

type SoundType =
  | 'BALL'
  | 'CAGE'
  | 'CLICK'
  | 'COMPLETE'
  | 'FAIL'
  | 'NUMBER'
  | 'RANDOM'
  | 'REVEAL'
  | 'START'
  | 'SUCK'
  | 'WIN'
  | 'COUNT'
  | 'CLAP';

interface SoundOptions {
  loop?: boolean;
  muted?: boolean;
  volume?: number;
  force?: boolean;
}

interface SoundRef {
  BALL: HTMLAudioElement;
  CAGE: HTMLAudioElement;
  CLICK: HTMLAudioElement;
  COMPLETE: HTMLAudioElement;
  FAIL: HTMLAudioElement;
  NUMBER: HTMLAudioElement;
  RANDOM: HTMLAudioElement;
  REVEAL: HTMLAudioElement;
  START: HTMLAudioElement;
  SUCK: HTMLAudioElement;
  WIN: HTMLAudioElement;
  COUNT: HTMLAudioElement;
  CLAP: HTMLAudioElement;
}

interface SoundProviderProps {
  enabledSound?: boolean;
  playAllSound: VoidFunction;
  stopAllSound: VoidFunction;
  onToggleSound: VoidFunction;
  stopSound: (src: SoundType) => void;
  playSound: (src: SoundType, options?: SoundOptions) => void;
}

const InitSound: SoundProviderProps = {
  enabledSound: false,
  playAllSound: () => {},
  stopAllSound: () => {},
  onToggleSound: () => {},
  stopSound: (src: SoundType) => {},
  playSound: (src: SoundType, options?: SoundOptions) => {},
};

const SoundContext = React.createContext<SoundProviderProps>(InitSound);
const SoundProvider = SoundContext.Provider;

export default ({ children }: { children?: ReactNode }) => {
  const [cookies, setCookies] = useCookies();
  const soundRef = useRef<SoundRef>({
    BALL: new Audio(SoundAssets.BALLS),
    CAGE: new Audio(SoundAssets.CAGE),
    CLICK: new Audio(SoundAssets.CLICK),
    COMPLETE: new Audio(SoundAssets.COMPLETE),
    FAIL: new Audio(SoundAssets.FAIL),
    NUMBER: new Audio(SoundAssets.NUMBER),
    RANDOM: new Audio(SoundAssets.RANDOM),
    REVEAL: new Audio(SoundAssets.REVEAL),
    START: new Audio(SoundAssets.START),
    SUCK: new Audio(SoundAssets.SUCK),
    WIN: new Audio(SoundAssets.WIN),
    COUNT: new Audio(SoundAssets.COUNT),
    CLAP: new Audio(SoundAssets.CLAP),
  });

  const [soundBlocked, setSoundBlocked] = useState<boolean>(true);
  const [enabledSound, setEnabledSound] = useState<boolean>(!cookies.sound || cookies.sound === 'ENABLED');

  const resetSound = useCallback((sound: HTMLAudioElement) => {
    sound.volume = 1;
    sound.loop = false;
    sound.muted = false;
    sound.currentTime = 0;
  }, []);

  const stopSound = useCallback(
    (src: SoundType) => {
      const sound = soundRef.current[src];
      if (!sound.paused) {
        sound.pause();
        resetSound(sound);
      }
    },
    [soundRef, resetSound],
  );

  const playSound = useCallback(
    (src: SoundType, options?: SoundOptions) => {
      return new Promise((resolve, reject) => {
        const sound = soundRef.current[src];
        // src === 'BALL' && console.log(src, sound);
        if (sound.paused && enabledSound) {
          sound.currentTime = 0;
          sound.loop = options?.loop || false;
          sound.muted = options?.muted || false;
          sound.volume = options?.volume === undefined ? 1 : options?.volume;
          sound
            .play()
            .then(() => {
              soundBlocked && setSoundBlocked(false);
              resolve({});
            })
            .catch(e => {
              __DEV__ && console.log(e);
              !soundBlocked && setSoundBlocked(true);
              reject(e);
            });
        }
      });
    },
    [soundRef, soundBlocked, enabledSound],
  );

  const playAllSound = useCallback(() => {
    return Promise.all(
      Object.keys(soundRef.current).map(src => playSound(src as SoundType, { muted: true, volume: 0 })),
    );
  }, [playSound]);

  const stopAllSound = useCallback(() => {
    return Promise.all(Object.keys(soundRef.current).map(src => stopSound(src as SoundType))).finally(() => {
      enabledSound && setEnabledSound(false);
    });
  }, [enabledSound, playAllSound]);

  const onToggleSound = useCallback(() => {
    if (!enabledSound) {
      playAllSound();
      setCookies('sound', 'ENABLED', { path: '/' });
    } else {
      stopAllSound();
      setCookies('sound', 'DISABLED', { path: '/' });
    }
    setEnabledSound(e => !e);
  }, [enabledSound, setEnabledSound, stopAllSound, playAllSound]);

  useEventListener('click', () => {
    if (soundBlocked && enabledSound) {
      playAllSound();
    }
  });

  const ctx = useMemo(
    () => ({
      enabledSound,
      stopSound,
      playSound,
      playAllSound,
      stopAllSound,
      onToggleSound,
    }),
    [enabledSound, stopSound, playSound, playAllSound, stopAllSound],
  );

  return <SoundProvider value={ctx}>{children}</SoundProvider>;
};

export const useSoundContext = () => React.useContext(SoundContext);
