import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { useEffect } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyAWu7DILG4e_0oQPuF2HVWOSL2F-Qfwz9I',
  authDomain: 'luckydragonpro-ecf5b.firebaseapp.com',
  projectId: 'luckydragonpro-ecf5b',
  storageBucket: 'luckydragonpro-ecf5b.appspot.com',
  messagingSenderId: '473541008472',
  appId: '1:473541008472:web:90f0170d080c4600147e2d',
  measurementId: 'G-VGLQMD1DV7',
};

export function useFirebase() {
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }, []);
};
