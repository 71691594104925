import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

const user: { userId?: number } = { userId: undefined };
type TYPE = 'screen' | 'error' | 'request';

export function useAnalyticScreen (pathname: string, search: any, userId?: number) {
  user.userId = userId;
  const analytic = getAnalytics();
  const data = {
    userId,
    search,
    pathname,
  };
  logEvent(analytic, `screen${userId ? '_' + userId : ''}`, data);
}

export function useAnalyticApi (api: string, param: any, type: TYPE = 'error') {
  const analytic = getAnalytics();
  const data = {
    userId: user.userId,
    api,
    param,
  };
  logEvent(analytic, `${type}${user.userId ? '_' + user.userId : ''}`, data);
}

export function useAnalyticUser (userId: number) {
  const analytic = getAnalytics();
  setUserId(analytic, userId.toString());
}
