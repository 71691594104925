import moment from "moment";
import { Types } from "src/generated/types";

const Formats = {
  id: (number: any, limit = 7) => {
    if (!!number && number.toString().length <= limit) {
      const id = number.toString();
      return id.length > 7 ? id : `000000${id}`.slice(-limit);
    }
    return number;
  },
  currency: (currency: any) => {
    if (!!currency) {
      return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return currency;
  },
  fraction: (number: any) => {
    if (!!number) {
      return parseFloat(number)
        .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        .toString();
    }
    return '0.00';
  },
  round: (number: any, precision: number) => {
    if (!!number && !!number.toString().trim()) {
      const factor = Math.pow(10, precision);
      const result = Math.round(number * factor) / factor;
      return result;
    }
    return 0;
  },
  roundCurrency: (currency: any, round = 2) => {
    return Formats.currency(Formats.round(currency, round));
  },
  price: (number: any) => {
    return (Formats.currency(Formats.round(Number(number) || 0, 3)) || '0').toString();
  },
  shortCurrency: (currency: any) => {
    const str: string = currency.toString();
    const length = str.length;
    if (length > 9) {
      return `${str.substring(0, length - 9)}B`;
    } else if (length > 6) {
      return `${str.substring(0, length - 6)}M`;
    } else if (length > 3) {
      return `${str.substring(0, length - 3)}K`;
    }
    return str;
  },
  dateTime: (dt: string, format: Types.DateTimeFormat = 'DD-MM-YYYY hh:mm A') => {
    return moment(dt.replace(' ', 'T')).format(format)
  }
};

export default Formats;
