import React from 'react';
import Router from './routes/routers';
import { AlertPopup, AlertSnackbar, AlertAnnouncement } from './components/alerts';
import { alertAnnouncementRef, alertPopupRef, alertSnackbarRef, useFirebase, useSecureUrl } from './hooks';

import SoundProvider from './contexts/sound-provider';
import AuthProvider from './contexts/auth-provider';
import { isMobile } from 'react-device-detect';

function App() {
  useFirebase();
  useSecureUrl();
  return (
    <AuthProvider>
      <div className={`flex-1 bg-white ${isMobile ? 'min-h-screen' : ''}`}>
        <AlertPopup ref={alertPopupRef} />
        <AlertSnackbar ref={alertSnackbarRef} />
        <AlertAnnouncement ref={alertAnnouncementRef} />
        <SoundProvider>
          <Router />
        </SoundProvider>
      </div>
    </AuthProvider>
  );
}

export default App;
