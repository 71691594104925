import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { alertError } from "src/hooks";
import { useAnalyticApi } from "src/hooks/use-analytic";

const axiosInstance = axios.create({ 
  baseURL:
  process.env.NODE_ENV === 'production' ? `https://${window.location.host}` : process.env.REACT_APP_API_URL,
  timeout: 100000,
  withXSRFToken: true,
  withCredentials: true,
  validateStatus: status => status >= 200 && status < 300,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    ...(process.env.NODE_ENV === 'production'
      ? { 'X-CSRF-TOKEN': document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content') }
      : {}),
  },
});

axiosInstance.interceptors.response.use(
  response => {
    if (response?.data?.status === 'success' || response.status === 200) {
      if (response?.data?.data) return response.data;
      return response;
    }
    return Promise.reject({ message: 'មានភាពមិនប្រក្រតីកើតឡើង', status: 404 });
  },
  (error: AxiosError<{ message?: string }>) => {
    useAnalyticApi(`${error.request.responseURL}`,
    {
      ...error.response?.data,
      data: error.config?.data,
    },
    'error');
    const status = error.response?.status;
    const disabledAlert = error.config?.headers?.DISABLED_ALERT === 'true';
    if (!disabledAlert && (status === 401 || status === 411 || status === 419)) {
      // alertError({
      //   message: 'មានឧបករណ៍ផ្សេងលុកចូលគណនីនេះ / សុវត្តិភាពបានហួសសពុលភាព។',
      //   title: 'សូមចូលម្ដងទៀត',
      // })
    }
    return Promise.reject({
      status,
      message: error?.response?.data?.message || error.message,
    });
  },
);

export const axiosPut = async (url: string, { arg }: { arg: any }) => {
  const id = arg.query && arg.query.id ? arg.query.id : '';
  const URL = `${url}${!!id ? '/' + id : ''}`;
  const res = await axiosInstance.put(URL, arg);
  return res.data;
};

export const axiosPost = async (url: string, { arg }: { arg: any }) => {
  const res = await axiosInstance.post(url, arg);
  return res.data;
};

export const axiosDelete = async (url: string, { arg }: { arg: any }) => {
  const id = arg.query && arg.query.id ? arg.query.id : '';
  const URL = `${url}${!!id ? '/' + id : ''}`;
  const res = await axiosInstance.delete(URL, { data: arg });
  return res.data;
};

export const axiosMutate = (method?: any) => {
  if (method === 'PUT') {
    return axiosPut;
  } else if (method === 'DELETE') {
    return axiosDelete;
  }
  return axiosPost;
};

export const axiosQuery = async (url: string, { arg }: { arg: any }) => {
  const query = Object.entries(arg || {})
    .filter((f) => f[1] != null && f[1] != undefined)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&');
  const URL = `${url}${!!query ? '?' : ''}${query}`;
  const res = await axiosInstance.get(URL);
  return res.data;
};

export default axiosInstance;


