import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Types } from 'src/generated/types';
import Formats from 'src/utils/format';

export interface AlertAnnouncementProps {
  message?: string;
  winners?: Types.JackpotWinner[];
}

export interface AlertAnnouncementRef {
  show: (params: AlertAnnouncementProps) => void;
}

const AlertAnnouncement = forwardRef((_, ref?: any) => {
  const [message, setMessage] = useState<string>('');
  const [winners, setWinners] = useState<Types.JackpotWinner[]>([]);

  const isVisible = useMemo(() => !!message || winners.length > 0, [message, winners]);

  const timeout = useMemo(() => {
    const extra = 10000 * winners.length;
    return message ? 60000 : 10000 + extra;
  }, [winners, message]);

  const show = (params: AlertAnnouncementProps) => {
    if (params.winners && params.winners?.length > 0) {
      setWinners(params.winners);
    }
    if (params.message) {
      setMessage(params.message);
    }
  };

  useImperativeHandle(ref, () => ({ show }));

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setMessage('');
        setWinners([]);
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [isVisible, timeout]);

  return (
    <div className={`fixed top-0 min-w-full bg-[#0000004a] py-4 md:p-5 z-[102] ${isVisible ? 'flex' : 'hidden'}`}>
      <LazyLoadComponent>
        <div
          className="flex-1 rounded-md md:rounded-lg px-3 md:px-5 md:py-2 border-3 border-white"
          style={{
            background:
              winners.length > 0
                ? 'linear-gradient(to right top, red, rgb(255, 56, 0) 30%, rgb(244, 0, 9) 50%, rgb(200, 4, 4))'
                : 'radial-gradient(circle at -0.8% 4.3%, rgb(59, 176, 255) 0%, rgb(0, 214, 255) 83.6%)',
          }}>
          <Marquee speed={100} gradient={false} className="h-10 md:h-14">
            {winners.map((w, i) => (
              <span
                key={i}
                className="text-white text-lg md:text-3xl font-bold font-battambang"
                style={{ textShadow: '#000 1px 1px 1px' }}>
                &emsp;&emsp;&emsp;&emsp; សំបុត្រលេខ #{w.bet_id} បានឈ្នះរង្វាន់
                {w.diff.toString() === '2' ? 'តូច Jackpot' : `ធំទី${w.diff} Jackpot`} ចំនួនទឹកប្រាក់{' '}
                {Formats.currency(w.price)}៛ &emsp;&emsp;&emsp;&emsp;
              </span>
            ))}
            {!!message ? (
              <span
                className="text-white text-lg md:text-3xl font-bold font-battambang"
                style={{ textShadow: '#000 1px 1px 1px' }}>
                &emsp;&emsp;&emsp;&emsp;
                {message}
                &emsp;&emsp;&emsp;&emsp;
              </span>
            ) : null}
          </Marquee>
        </div>
      </LazyLoadComponent>
    </div>
  );
});

export default AlertAnnouncement;
