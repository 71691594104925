import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { ModalContainer } from '../modals';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Iconify from '../iconify';

export interface AlertSnackbarProps {
  message?: string;
  status: 'SUCCESS' | 'ERROR' | 'LOADING';
}

function AlertIcon({ msg, isError }: { isError: boolean; msg: AlertSnackbarProps | null }) {
  if (msg) {
    if (msg.status === 'LOADING') {
      return <Iconify icon={'svg-spinners:tadpole'} className={`text-orange-500 w-20 h-20`} />;
    }
    return (
      <Iconify
        icon={isError ? 'line-md:close-circle' : 'line-md:confirm-circle'}
        className={`${isError ? 'text-[#f72322]' : 'text-[#00ff07]'} w-24 h-24`}
      />
    );
  }
  return null;
}

export interface AlertSnackbarRef {
  show: (props: AlertSnackbarProps) => void;
}

const AlertSnackbar = forwardRef((_, ref?: any) => {
  const [msg, setMsg] = useState<AlertSnackbarProps | null>(null);

  const isError = useMemo(() => msg?.status === 'ERROR', [msg]);
  const isLoading = useMemo(() => msg?.status === 'LOADING', [msg]);

  const onClose = () => {
    setMsg(null);
  };

  const show = (_msg: AlertSnackbarProps) => {
    setMsg(_msg);
  };

  useEffect(() => {
    if (msg) {
      const timer = setTimeout(onClose, 850);
      return () => clearTimeout(timer);
    }
  }, [msg]);

  useImperativeHandle(ref, () => ({ show }));

  return (
    <ModalContainer visible={true} hidden={!msg} className="z-[101]">
      <div className="flex-1 items-center justify-center p-2">
        <div
          className={`bg-white p-2 rounded-2xl w-full max-w-lg relative border-5 border:bg-slate-100 ${
            isError ? 'border-error' : isLoading ? 'border-orange-600' : 'border-success'
          }`}>
          <div className={`mb-2 flex-row items-center w-full rounded`}>
            <AlertIcon msg={msg} isError={isError} />
            <label className="ml-3 text-base md:text-xl text-[#333] font-battambang">
              {isLoading ? 'ប្រព័ន្ធកំពុងផ្ទុកការផ្សងសំណាង' : msg?.message}
            </label>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
});

export default AlertSnackbar;
