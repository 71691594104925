import moment from "moment";
import { Types } from "src/generated/types";
import Setting from "./setting";
import Formats from "./format";

export const __DEV__ = process.env.NODE_ENV === 'development';
export const TODAY = moment().format('YYYY-MM-DD');

export function removeAllCookie() {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}

export function resetOldToken() {
  var reset: any = false;
  let cookieArray = document.cookie.split(';');
  for (var i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].split('=');
    if (cookiePair[0].trim() == 'resettoken') {
      reset = decodeURIComponent(cookiePair[1]);
    }
  }
  if (!reset) {
    removeAllCookie();
    document.cookie = `resettoken=true; expires=1000000000; Path=/`;
  }
}

export function getRandom(min = 1, max = 59) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const COLUMNS = 10;
const MAX = 10;

export function generateHistory(data: Types.Result[]) {
  let r_t_i = 0;
  let c_t_i = 0;
  let r_s_i = 0;
  let c_s_i = 0;

  let types: any[][] = [[], [], [], [], [], [], []];
  let statuses: any[][] = [[], [], [], [], [], [], []];

  data.map((r, i) => {
    const type = r?.type?.replace('RANGE_', '');
    const status = r.status === 'OVER' ? 'O' : 'U';
    if (i === 0) {
      types[0][0] = type;
      statuses[0][0] = status;
    } else {
      if (types[r_t_i][c_t_i] === type) {
        if (types[r_t_i + 1] && types[r_t_i + 1][c_t_i] === undefined) {
          r_t_i++;
        } else {
          c_t_i++;
          if (types[r_t_i + 1] !== undefined && types[r_t_i + 1][c_t_i] === undefined) {
            types[r_t_i + 1][c_t_i] = '';
          }
        }
      } else {
        r_t_i = 0;
        c_t_i = types[r_t_i].length;
      }
      types[r_t_i][c_t_i] = type;

      if (statuses[r_s_i][c_s_i] === status) {
        if (statuses[r_s_i + 1] && statuses[r_s_i + 1][c_s_i] === undefined) {
          r_s_i++;
        } else {
          c_s_i++;
          if (statuses[r_s_i + 1] !== undefined && statuses[r_s_i + 1][c_s_i] === undefined) {
            statuses[r_s_i + 1][c_s_i] = '';
          }
        }
      } else {
        r_s_i = 0;
        c_s_i = statuses[r_s_i].length;
      }
      statuses[r_s_i][c_s_i] = status;
    }
  });

  const types_length = types.reduce((a, b) => (a > b.length ? a : b.length), 0);
  const status_length = statuses.reduce((a, b) => (a > b.length ? a : b.length), 0);

  const types_min = types_length > MAX ? types_length - MAX : types_length;
  const status_min = status_length > MAX ? status_length - MAX : status_length;
  types = types.reduce((a, b) => {
    const x = b.slice(types_min, types_length);
    a.push([...x, ...Array(COLUMNS - x.length).fill('')]);
    return a;
  }, []);
  statuses = statuses.reduce((a, b) => {
    const x = b.slice(status_min, status_length);
    a.push([...x, ...Array(COLUMNS - x.length).fill('')]);
    return a;
  }, []);

  return { types, statuses };
};

export function groupTickets(data: Types.Ticket[]) {
  const list:Types.GroupTicket[] = [];
  data.map(i => {
    const index = list.findIndex(f => f.id === i.result_id);
    const result = list[index] || {};

    const groups = result.groups || [];
    const group_index = groups.findIndex(f => f.id === i.group_id);
    const g = groups[group_index] || {};

    const tickets = g.tickets || [];

    tickets.push(i);

    const group: Types.GroupItem = {
      tickets,
      id: i.group_id,
      created_at: i.created_at,
      result_id: i.result_id,
      total_bet_amount: Number(g.total_bet_amount || 0) + i.amount,
      total_pay_amount: Number(g.total_pay_amount || 0) + i.payout,
      total_win_jackpot_amount: Number(g.total_win_jackpot_amount || 0) + Number(i.jackpot_win_amount || 0),
      outstanding: i.outstanding,
    };

    if (group_index >= 0) {
      groups[group_index] = group;
    } else {
      groups.push(group);
    }

    if (index >= 0) {
      list[index] = {
        ...result,
        groups,
      };
    } else {
      list.push({
        groups,
        id: i.result_id,
        jackpot: i.jackpot_result || null,
      });
    }
  });
  return list;
}

export function getReleasedResult(total: number): Types.ShortResult | null {
  if (total > 0) {
    if (total <= 238) {
      return { result: '1', status: 'U' };
    } else if (total <= 274) {
      return { result: '2', status: 'U' };
    } else if (total <= 300) {
      return { result: '3', status: 'U' };
    }else if (total <= 325) {
      return { result: '3', status: 'O' };
    }  else if (total <= 361) {
      return { result: '4', status: 'O' };
    } else if (total <= 545) {
      return { result: '5', status: 'O' };
    }
  }
  return null;
}

export function getShortResult(total: number) {
  if (total > 0) {
    if (total <= 238) {
      return '1 (តូច)';
    } else if (total <= 274) {
      return '2 (តូច)';
    } else if (total <= 300) {
      return '3 (តូច)';
    }else if (total <= 325) {
      return '3 (ធំ)';
    }  else if (total <= 361) {
      return '4 (ធំ)';
    } else if (total <= 545) {
      return '5 (ធំ)';
    }
  }
  return '';
}

export function getBetResponseError(msg: String) {
  if (msg.includes(Setting.WARNING.BET_JACKPOT_OVER_LIMIT)) {
    return `${Setting.ERRORS.bet_jackpot_over_limit} ${msg.replace(Setting.WARNING.BET_JACKPOT_OVER_LIMIT, '')}។`;
  } else if (msg === Setting.WARNING.NOT_ENOUGH_MONEY) {
    return Setting.ERRORS.not_enough_money;
  } else if (msg === Setting.WARNING.TIMEOUT) {
    return Setting.ERRORS.timeout;
  } else if (msg.includes(Setting.WARNING.MIN)) {
    const index = msg.indexOf('.');
    const amount = msg.substring(0, index).replace(Setting.WARNING.MIN, '');
    return `${Setting.ERRORS.min_bet} ${Formats.currency(amount)}៛ ។`;
  } else if (msg.includes(Setting.WARNING.MAX)) {
    const index = msg.indexOf('.');
    const amount = msg.substring(0, index).replace(Setting.WARNING.MAX, '');
    return `${Setting.ERRORS.max_bet} ${Formats.currency(amount)}៛ ។`;
  }
  return Setting.ERRORS.bet_failed;
}

export function getNumberFormat(numbers: number[]) {
  let prev = 0;
  let start = 0;
  const length = numbers.length;
  let format = length === 1 ? numbers[0]?.toString().padStart(2, '0') : "";
  numbers.map((number, key) => {
    const head = format ? ',' : '';
    if (key === 0) {
      prev = number;
      start = number;
    } else {
      const isLastItem = key + 1 === length;
      const next = prev + 1;
      if (next === number && !isLastItem) {
        prev = number;
      } else {
        let n = number.toString().padStart(2, '0');
        n = format.includes(n) ? '' : n;
        let p = prev.toString().padStart(2, '0');
        p = format.includes(p) ? '' : p;
        if (start === prev) {
          format += `${head}${p}${isLastItem ? ',' + n : ''}`;
        } else if (start < prev && start + 1 < prev) {
          const tail = next === number ? n : `${p},${n}`
          format += `${head}${start.toString().padStart(2, '0')}-${tail}`;
        }  else {
          format += `${head}${start.toString().padStart(2, '0')},${p}${isLastItem ? ',' + n : ''}`;
        }

        prev = number;
        start = number;
      }
    }
  });
  
  return Array.from(new Set(format.split(',').filter(f => !!f))).join(',');
}

const POSTS = ['A', 'B', 'C', 'D', 'E'];
export function checkResultWinner (results: string[], number: string, posts: string[]) {
  const n = number.split('-');
  const length = n.length;
  const firstNumber = n[0] || '';
  const secondNumber = n[1] || '';
  const res: any[] = [];
  results.map((r, i) => {
    const p = POSTS[i];
    if (
      posts.includes(p) &&
      (r === firstNumber || (length === 2 && Number(r) >= Number(firstNumber) && Number(r) <= Number(secondNumber)))
    ) {
      res.push(Setting.POSTS[i]);
    }
  });
  return res;
};