import Paths from 'src/paths';
import MainMenuSliderItem from './menu-slider-item';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageAssets from 'src/assets/images';

interface MainMenuSliderProps {
  isFullWidth?: boolean;
  onInvisibleMenu?: (value: boolean) => void;
}

export default function MainMenuSlider(props: MainMenuSliderProps) {
  return (
    <div
      className={`bg-white w-full ${
        props.isFullWidth ? 'min-w-[14rem]' : 'min-w-[3.15rem]'
      } max-w-[16rem] h-full overflow-y-auto transition-all duration-100 ease-linear`}>
      <a href="/" className="px-2 py-1 border-b-1 lg:hidden">
        <LazyLoadImage src={ImageAssets.Logo} className="h-9" />
      </a>
      {Paths.menus.map((menu, i) => (
        <MainMenuSliderItem key={i} {...menu} isFullWidth={props.isFullWidth} onInvisibleMenu={props.onInvisibleMenu} />
      ))}
      <label className="text-center text-sm text-gray-300 absolute bottom-3 self-center">
        {props.isFullWidth ? 'Version 2.0' : 'V2.0'}
      </label>
    </div>
  );
}
