import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useQueryString() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = useMemo(() => {
    const params: any = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams])

  const getQueryString = (key: string, defaultValue: any = null) => {
    const query = searchParams.get(key);
    return !!query && query.trim() !== 'null' ? query.trim() : defaultValue;
  };
  const setQueryString = (params: any) => {
    const query = Object.entries({...queryString, ...params}).reduce((obj, p) => Object.assign(obj, { [p[0]]: String(p[1]) }), {});
    setSearchParams(query);
  };
  return { 
    queryString, 
    getQueryString, 
    setQueryString,
    isEmptyQueryString: Object.entries(queryString).length === 0
  };
};
