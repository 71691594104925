import { lazy } from 'react';
import { Types } from 'src/generated/types';
import Paths from 'src/paths';

const AuthLoginPage = lazy(() => import('src/pages/auths/auth-login-page'));
const AuthLogoutPage = lazy(() => import('src/pages/auths/auth-logout-page'));

export const authRouters: Types.RouteObject[] = [
  { path: Paths.routes.auth.login, element: <AuthLoginPage /> },
  { path: Paths.routes.auth.logout, element: <AuthLogoutPage /> },
];
